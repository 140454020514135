import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import { logError, logInfo } from 'src/utils/logUtils';
import {
  PriceApiFetchPriceRequest,
  PriceApiFetchPriceResponse,
} from './priceApiTypes';

export const onFetchPriceQueryStarted = async (
  props: PriceApiFetchPriceRequest,
  api: QueryLifecycleApi<
    PriceApiFetchPriceRequest,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    PriceApiFetchPriceResponse,
    'priceApi'
  >
) => {
  const { queryFulfilled } = api;
  const correlationId = generateCorrelationId();
  logInfo({
    message: 'sending POST /api/health/nz/price',
    correlationId,
  });

  try {
    await queryFulfilled;
    logInfo({
      message: 'POST /api/health/nz/price succeeded',
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST /api/health/nz/price failed',
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};
