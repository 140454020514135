import Divider from '@nib-components/divider';
import Heading from '@nib-components/heading';
import InfoBox from '@nib/info-box';
import { Box, Column, Columns, Container, Stack } from '@nib/layout';
import { Formik } from 'formik';
import { clone } from 'ramda';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdviserDeclaration from 'src/components/BuyNow/AdviserDeclaration';
import ApplicantSummaryCard from 'src/components/BuyNow/ApplicantSummaryCard';
import DeclarationStep from 'src/components/BuyNow/DeclarationStep';
import MembershipStep from 'src/components/BuyNow/MembershipStep';
import PaymentDetailsStep from 'src/components/BuyNow/PaymentDetailsStep';
import PriceInfoBox from 'src/components/BuyNow/PriceInfoBox';
import YourDetailsStep from 'src/components/BuyNow/YourDetailsStep';
import CustomisedForm from 'src/components/form/CustomisedForm';
import StepHeading from 'src/components/heading/StepHeading';
import TitlePanel from 'src/components/heading/TitlePanel';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import MarkdownAlert from 'src/components/MarkdownAlert';
import MarkdownContent from 'src/components/MarkdownContent';
import BottomButtons from 'src/components/navigation/BottomButtons';
import NeedAdviceButton from 'src/components/NeedAdvice/NeedAdviceButton';
import SpecialOfferAlertWrapper from 'src/components/SpecialOffer/SpecialOfferAlertWrapper';
import { PriceContext } from 'src/contexts/PriceContext';
import { useSubmitJoin } from 'src/hooks/useSubmitJoin';
import { useHppRegisterMutation } from 'src/services/billing/billingApi';
import { BillingServiceHppRegisterResponse } from 'src/services/billing/billingApiTypes';
import { useValidateSpecialOfferQuery } from 'src/services/join/joinApi';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { BuyNowFormData } from 'src/types/BuyNowForm';
import { BuyNowAdviserDeclarationContent } from 'src/types/Content/BuyNowContent';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import {
  transformFormDataToPartialQuoteSession,
  transformPartialQuoteSessionToFormData,
} from 'src/utils/buyNowUtils';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { getFormPageByIndex } from 'src/utils/formPageUtils';
import gtmUtils from 'src/utils/gtmUtils';
import {
  createValidateSpecialOfferRequest,
  isEligibleForSpecialOffer,
} from 'src/utils/joinApiUtils';
import {
  createApplicant,
  createApplicantExtraDetails,
} from 'src/utils/quoteUtils';
import BuyNowSchema, {
  BuyNowAdviserDeclarationSchema,
  BuyNowDeclarationSchema,
  BuyNowYourDetailsSchema,
} from 'src/utils/validation/schema/BuyNowSchema';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Banner from '../components/Banner';
import {
  ApplicantDetails,
  BuyNowStep,
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from '../types/QuoteSession';

const content = config.brand.content.buyNow;
const hasMemberProducts = config.brand.hasMemberProducts;
const hasAdviser = config.brand.hasAdviser;

const FinancialStrengthTitleContainer = styled('div')`
  position: relative;
`;

const FinancialStrengthRating = styled('div')`
  position: absolute;
  left: 1.75rem;
  top: 1.75rem;
  ${breakpoint('sm')`
    top: 0.75rem;
  `}
`;

const FinancialStrengthTitle = styled('div')`
  margin-left: 4rem;
  margin-right: 5rem;
`;

const BuyNowPage = (props: FormPageProps) => {
  const { onSubmit } = props;
  const quoteSession = clone(props.quoteSession);
  const { paymentDetails, buyNowStep } = quoteSession;

  const navigate = useNavigate();

  const [isSubmitting, setSubmitting] = React.useState(false);
  const submitJoin = useSubmitJoin();
  const [triggerHppRegistration] = useHppRegisterMutation();

  const { isFetching, data: priceData } = React.useContext(PriceContext);
  const { data: validateSpecialOfferResponse } = useValidateSpecialOfferQuery(
    createValidateSpecialOfferRequest(quoteSession)
  );

  // Whether we should show the success message for membership validation
  const [showMemberValidationSuccess, setShowMemberValidationSuccess] =
    React.useState(false);

  // Find the BuyNowStep to display for a given requested step.
  // If any previous steps fail validation, stay on the step with validation errors.
  const getBuyNowStep = (
    step: BuyNowStep,
    formData: BuyNowFormData
  ): BuyNowStep => {
    if (step > BuyNowStep.YourDetails) {
      try {
        BuyNowYourDetailsSchema.validateSync(formData.applicantExtraDetails);
      } catch (e) {
        return BuyNowStep.YourDetails;
      }
    }
    if (step > BuyNowStep.Declaration) {
      try {
        if (config.brand.hasAdviser) {
          BuyNowAdviserDeclarationSchema(BuyNowStep.Declaration).validateSync(
            formData.adviserDeclaration
          );
        } else {
          BuyNowDeclarationSchema.validateSync(formData.declaration);
        }
      } catch (e) {
        return BuyNowStep.Declaration;
      }
    }
    // All is OK
    return step;
  };

  // Handle a completed form.
  // Throws an error if the API call fails.
  const handleBuy = async (
    q: QuoteSession
  ): Promise<BillingServiceHppRegisterResponse | null> => {
    switch (q.paymentDetails.paymentMethod) {
      case PaymentMethod.CreditCard:
        // The submit button is disabled when priceData doesn't exist, so
        // we can assume it is here
        const price = priceApiUtils.getPolicyTotalPriceForQuoteSession(
          priceData,
          q
        );
        const response = await triggerHppRegistration({
          paymentDate: q.paymentDetails.firstPaymentDate,
          premium: `${formatCurrency(
            price
          )} ${q.paymentDetails.frequency.toLowerCase()}`,
          correlationId: generateCorrelationId(),
        }).unwrap();
        return response;
      case PaymentMethod.DirectDebit:
        await submitJoin(q);
        return null;
    }
    return null;
  };

  // Remember pricing before submitting the Your Details step, so we can display it
  // in a message if this causes a pricing change.
  const [totalPriceBeforeStepChange, setTotalPriceBeforeStepChange] =
    React.useState<number>(0);
  const [
    paymentFrequencyBeforeStepChange,
    setPaymentFrequencyBeforeStepChange,
  ] = React.useState<PaymentFrequency>();
  const totalPrice =
    !isFetching && priceData ? priceApiUtils.getPolicyTotalPrice(priceData) : 0;

  // Transform QuoteSession into correct structure for form validation
  const applicantExtraDetails: BuyNowFormData =
    transformPartialQuoteSessionToFormData(quoteSession);

  // Find which step we should be on
  const currentStep = getBuyNowStep(buyNowStep, applicantExtraDetails);

  // Set it into our form data so validation can access it
  applicantExtraDetails.buyNowStep = currentStep;

  // When we change Buy Now step in local state, scroll the screen to the top of that section.
  // Note that src/style.css sets the scroll offset, so that we don't scroll things
  // underneath the floating header.
  React.useEffect(() => {
    const scrollTarget = document.getElementById('buynowstep-' + currentStep);
    if (scrollTarget) {
      scrollTarget.scrollIntoView();
    }
  }, [currentStep]);

  useEffect(() => {
    gtmUtils.viewFinaliseAndBuy(quoteSession.applicantDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Find the customised product types for each applicant
  const getProductCodes = (applicant: ApplicantDetails): string[] => {
    const policyDetails = quoteSession.memberPolicyDetails[applicant.id];
    const productCodes: string[] = [];
    if (policyDetails.hospitalProductCode) {
      productCodes.push(policyDetails.hospitalProductCode);
    }
    if (policyDetails.everydayProductCode) {
      productCodes.push(policyDetails.everydayProductCode);
    }
    return productCodes;
  };

  // Find the customised excess for each applicant
  const getExcess = (applicant: ApplicantDetails): number | undefined => {
    const policyDetails = quoteSession.memberPolicyDetails[applicant.id];
    if (!policyDetails.hospitalProductCode) {
      return undefined;
    }
    const excess = parseInt(policyDetails.excess);
    if (isNaN(excess)) {
      return undefined;
    }
    return excess;
  };

  // Find the customised non-PHARMAC Plus option for each applicant
  const getNonPharmacPlus = (
    _applicant: ApplicantDetails
  ): number | undefined => {
    const nonPharmacPlus = parseInt(
      quoteSession.memberPolicyDetails[_applicant.id].nonPharmacPlus
    );
    if (isNaN(nonPharmacPlus)) {
      return undefined;
    }
    return nonPharmacPlus;
  };

  // Ensure we have a >=16yo applicant marked as policy owner.
  // If needed, add a guardian, or remove them if they are no longer needed.
  const handleGuardian = (
    values: BuyNowFormData,
    setValues: (nextState: BuyNowFormData, shouldValidate?: boolean) => void
  ) => {
    let newValues = clone(values);
    let hasChanges = false;

    // Remove policy owner status from any <16yos
    newValues.applicantExtraDetails.forEach((a, i) => {
      if (!a.isGuardian && a.isPolicyOwner && parseInt(a.age) < 16) {
        newValues.applicantExtraDetails[i].isPolicyOwner = false;
        hasChanges = true;
      }
    });

    // If we have only <16yo's, add a guardian.
    if (
      newValues.applicantExtraDetails.every(
        (a) => !a.isGuardian && parseInt(a.age) < 16
      )
    ) {
      newValues.applicantExtraDetails.push({
        ...createApplicant(),
        ...createApplicantExtraDetails(),
        isPolicyOwner: true,
        isGuardian: true,
        hasHospitalCover: false, // Guardians have no cover.
      });
      hasChanges = true;
    }

    // If the guardian is the policy owner, and we have someone else who could be the
    // new policy owner, remove the guardian.
    if (
      newValues.applicantExtraDetails.find((a) => a.isGuardian)
        ?.isPolicyOwner &&
      newValues.applicantExtraDetails.find(
        (a) => !a.isGuardian && parseInt(a.age) >= 16
      )
    ) {
      newValues.applicantExtraDetails = newValues.applicantExtraDetails.filter(
        (a) => !a.isGuardian
      );
      hasChanges = true;
    }

    // If nobody is the policy owner, make it the first valid user
    if (!newValues.applicantExtraDetails.find((a) => a.isPolicyOwner)) {
      const i = newValues.applicantExtraDetails.findIndex(
        (a) => parseInt(a.age) >= 16
      );
      if (i !== -1) {
        newValues.applicantExtraDetails[i].isPolicyOwner = true;
        hasChanges = true;
      }
    }

    // Set our new form state if needed.
    if (hasChanges) {
      setValues(newValues, true);
    }
  };

  return (
    <Box>
      <Banner />
      <HelmetComponent content={config.brand.content.buyNow.helmet} />
      <TitlePanel
        title={content.title}
        subTitle={content.subtitle}
        showSpecialOffer={false}
      />
      <Formik
        enableReinitialize={true}
        initialValues={applicantExtraDetails}
        onSubmit={async (values) => {
          const newQuoteSession = clone(quoteSession);
          let nextPage = FormPage.BuyNow;
          switch (currentStep) {
            case BuyNowStep.YourDetails:
              const { applicantDetails, applicantExtraDetails } =
                transformFormDataToPartialQuoteSession(values);
              // Add our new form data to the quote session.
              // Guardians have no cover.
              newQuoteSession.updateAllApplicantDetails(applicantDetails);
              newQuoteSession.updateAllApplicantExtraDetails(
                applicantExtraDetails
              );
              newQuoteSession.yourDetailsDeclaration =
                values.yourDetailsDeclaration;
              newQuoteSession.buyNowStep = hasMemberProducts
                ? BuyNowStep.AaMembership
                : BuyNowStep.Declaration;
              // Remember the current value of the total price and payment frequency, so we can compare on the next step
              if (!isFetching && priceData) {
                setTotalPriceBeforeStepChange(
                  priceApiUtils.getPolicyTotalPrice(priceData)
                );
                setPaymentFrequencyBeforeStepChange(
                  quoteSession.paymentDetails.frequency
                );
              }
              break;
            case BuyNowStep.AaMembership:
              newQuoteSession.buyNowStep = BuyNowStep.Declaration;
              newQuoteSession.hasMember = values.hasMember;
              newQuoteSession.externalMemberApplicant = values.hasMember
                ? values.externalMemberApplicant
                : '';
              newQuoteSession.externalMemberNumber = values.hasMember
                ? values.externalMemberNumber
                : '';
              // Remember the current value of the total price and payment frequency, so we can compare on the next step
              if (!isFetching && priceData) {
                setTotalPriceBeforeStepChange(
                  priceApiUtils.getPolicyTotalPrice(priceData)
                );
                setPaymentFrequencyBeforeStepChange(
                  quoteSession.paymentDetails.frequency
                );
              }
              break;
            case BuyNowStep.Declaration:
              if (config.brand.hasAdviser) {
                newQuoteSession.adviserDeclaration = values.adviserDeclaration;
                newQuoteSession.directDebitAdviserDeclaration =
                  values.directDebitAdviserDeclaration;
                newQuoteSession.creditCardAdviserDeclaration =
                  values.creditCardAdviserDeclaration;
              } else {
                newQuoteSession.declaration = values.declaration;
              }
              newQuoteSession.buyNowStep = BuyNowStep.PaymentDetails;
              break;
            case BuyNowStep.PaymentDetails:
              nextPage = FormPage.Thankyou;
              newQuoteSession.paymentDetails = values.paymentDetails;
              break;
          }
          newQuoteSession.enforceRules();
          // Save the user's details into the quote session
          onSubmit(null, newQuoteSession);
          // If we're going to the Thankyou page, trigger the Join
          // behaviour.  If we got a billing session ID, save that
          // into the quote session.
          let redirectURL: string | null = null;
          try {
            if (nextPage === FormPage.Thankyou) {
              setSubmitting(true);
              const response = await handleBuy(newQuoteSession);
              if (response) {
                newQuoteSession.paymentDetails.sessionId = response.sessionId;
                redirectURL = response.windcaveUrl;
                // Save the updated session ID.  This results in the cart being updated
                // twice, but we need to save the session before we call handleBuy so that the user's
                // details are persisted. If we have an error in the Join/Billing service,
                // the user is taken to the Error page and we want the above details to be
                // remembered when they click the 'Retry' button.
                onSubmit(null, newQuoteSession);
              }
            }
            // Make any redirects necessary after submit.
            if (nextPage === FormPage.Thankyou) {
              switch (newQuoteSession.paymentDetails.paymentMethod) {
                case PaymentMethod.DirectDebit:
                  navigate('/welcome');
                  break;
                case PaymentMethod.CreditCard:
                  if (redirectURL) {
                    window.location.assign(redirectURL);
                  }
                  break;
              }
            }
          } catch (e) {
            // If an API call fails, go to the Error page, returning to Buy Now.
            navigate(
              `/error?returnURL=${getFormPageByIndex(FormPage.BuyNow)!.path}`
            );
          }
        }}
        validationSchema={BuyNowSchema}
      >
        {(formikProps) => {
          const { values, handleReset, handleSubmit, setValues } = formikProps;
          handleGuardian(values, setValues);
          return (
            <CustomisedForm
              id="buyNow"
              name="buyNow"
              formMode="light"
              containerWidth="100%"
              spaceChildren={false}
              onReset={handleReset}
              onSubmit={handleSubmit}
            >
              <Container>
                <Stack space={5}>
                  <Columns space={6} collapseBelow="lg" reverse={true}>
                    <Column width="1/3">
                      <Stack space={5}>
                        {quoteSession.applicantDetails.map(
                          // We are showing details for each person using their saved details, rather
                          // than the details we are currently entering into the form.
                          (applicant, i) => {
                            const name = `${
                              applicant.firstName
                                ? applicant.firstName
                                : `Person ${i + 1}`
                            }`;
                            let price = 0;
                            try {
                              price =
                                !isFetching && priceData
                                  ? priceApiUtils.getApplicantTotalPrice(
                                      applicant,
                                      quoteSession,
                                      priceData
                                    )
                                  : 0;
                            } catch (_err) {
                              // When changing between hasMember=true|false, this can
                              // throw, as the member has different products than the price data.
                            }
                            return (
                              <ApplicantSummaryCard
                                key={`applicant-summary-card-${i}`}
                                name={name}
                                price={price}
                                frequency={paymentDetails.frequency}
                                age={applicant.age}
                                gender={applicant.gender}
                                smoker={applicant.smoker === 'Yes'}
                                productCodes={getProductCodes(applicant)}
                                excess={getExcess(applicant)}
                                nonPharmacPlus={getNonPharmacPlus(applicant)}
                                hasSpecialOffer={isEligibleForSpecialOffer(
                                  applicant.id,
                                  validateSpecialOfferResponse
                                )}
                              />
                            );
                          }
                        )}
                        <SpecialOfferAlertWrapper />
                      </Stack>
                    </Column>
                    <Column width={{ xs: 'content', lg: '2/3' }}>
                      <Stack space={6}>
                        <>
                          {/*
                            We let YourDetailsStep render the title when it is visible,
                            so it can lay out the policy holder checkbox.
                            Since the checkbox changes the heading's position, we have to pad the top a little
                            so it doesn't "jump" when the user clicks the Edit button.
                          */}
                          {currentStep !== BuyNowStep.YourDetails && (
                            <Box marginTop={5}>
                              <div id="buynowstep-0">
                                <StepHeading
                                  visible={false}
                                  disabled={false}
                                  onEdit={() => {
                                    quoteSession.buyNowStep =
                                      BuyNowStep.YourDetails;
                                    onSubmit(null, quoteSession);
                                  }}
                                >
                                  1. {content.step1.title}
                                </StepHeading>
                              </div>
                            </Box>
                          )}
                          {currentStep === BuyNowStep.YourDetails && (
                            <div id="buynowstep-0">
                              <YourDetailsStep
                                applicantExtraDetails={
                                  values.applicantExtraDetails
                                }
                              />
                            </div>
                          )}
                          {currentStep === BuyNowStep.YourDetails || (
                            <Divider />
                          )}
                          {currentStep ===
                            (hasMemberProducts
                              ? BuyNowStep.AaMembership
                              : BuyNowStep.Declaration) &&
                            totalPrice &&
                            totalPriceBeforeStepChange &&
                            paymentFrequencyBeforeStepChange &&
                            (totalPrice !== totalPriceBeforeStepChange ||
                              quoteSession.paymentDetails.frequency !==
                                paymentFrequencyBeforeStepChange) && (
                              <PriceInfoBox
                                fromAmount={totalPriceBeforeStepChange}
                                fromFrequency={paymentFrequencyBeforeStepChange}
                                amount={totalPrice}
                                frequency={
                                  quoteSession.paymentDetails.frequency
                                }
                              />
                            )}
                          {hasMemberProducts && (
                            // Only show AA Membership step if we have member products
                            <Stack space={6}>
                              <div id="buynowstep-1">
                                <StepHeading
                                  visible={
                                    currentStep === BuyNowStep.AaMembership
                                  }
                                  disabled={
                                    currentStep < BuyNowStep.AaMembership
                                  }
                                  onEdit={() => {
                                    quoteSession.buyNowStep =
                                      BuyNowStep.AaMembership;
                                    onSubmit(null, quoteSession);
                                  }}
                                >
                                  2. {content.step2!.title}
                                </StepHeading>
                              </div>
                              <>
                                {currentStep === BuyNowStep.AaMembership && (
                                  <MembershipStep
                                    hasMember={values.hasMember}
                                    applicants={values.applicantExtraDetails}
                                    setSuccess={setShowMemberValidationSuccess}
                                  />
                                )}
                              </>
                              <>
                                {currentStep === BuyNowStep.AaMembership || (
                                  <Divider />
                                )}
                              </>
                            </Stack>
                          )}
                          <div id="buynowstep-2">
                            <Stack space={5}>
                              {hasMemberProducts &&
                                !showMemberValidationSuccess &&
                                currentStep === BuyNowStep.Declaration &&
                                totalPrice &&
                                totalPriceBeforeStepChange &&
                                paymentFrequencyBeforeStepChange &&
                                (totalPrice !== totalPriceBeforeStepChange ||
                                  quoteSession.paymentDetails.frequency !==
                                    paymentFrequencyBeforeStepChange) && (
                                  <PriceInfoBox
                                    fromAmount={totalPriceBeforeStepChange}
                                    fromFrequency={
                                      paymentFrequencyBeforeStepChange
                                    }
                                    amount={totalPrice}
                                    frequency={
                                      quoteSession.paymentDetails.frequency
                                    }
                                  />
                                )}
                              {showMemberValidationSuccess && (
                                <MarkdownAlert
                                  type="success"
                                  variation="soft"
                                  content={
                                    content.step2!
                                      .externalMemberNumberSuccessInfoText
                                  }
                                />
                              )}
                              <StepHeading
                                visible={currentStep === BuyNowStep.Declaration}
                                disabled={currentStep < BuyNowStep.Declaration}
                                onEdit={() => {
                                  quoteSession.buyNowStep =
                                    BuyNowStep.Declaration;
                                  onSubmit(null, quoteSession);
                                }}
                              >
                                {hasMemberProducts ? '3. ' : '2. '}
                                {content.step3.title}
                              </StepHeading>
                            </Stack>
                          </div>
                          {currentStep === BuyNowStep.Declaration &&
                            (hasAdviser ? (
                              <>
                                <AdviserDeclaration
                                  prefix="adviserDeclaration"
                                  content={
                                    config.brand.content.buyNow
                                      .step3 as BuyNowAdviserDeclarationContent
                                  }
                                />
                                <BottomButtons
                                  submitButtonText={
                                    config.brand.content.buyNow.step3
                                      .nextButtonText
                                  }
                                  submitButtonColor="dark"
                                  submitButtonSize="medium"
                                />
                              </>
                            ) : (
                              <DeclarationStep
                                declarationDetails={values.declaration!}
                              />
                            ))}
                          {currentStep === BuyNowStep.Declaration || (
                            <Divider />
                          )}
                          <div id="buynowstep-3">
                            <StepHeading
                              visible={
                                currentStep === BuyNowStep.PaymentDetails
                              }
                              disabled={currentStep < BuyNowStep.PaymentDetails}
                              onEdit={() => {
                                quoteSession.buyNowStep =
                                  BuyNowStep.PaymentDetails;
                                onSubmit(null, quoteSession);
                              }}
                            >
                              {hasMemberProducts ? '4. ' : '3. '}
                              {content.step4.title}
                            </StepHeading>
                          </div>
                          {currentStep === BuyNowStep.PaymentDetails && (
                            <PaymentDetailsStep
                              paymentDetails={values.paymentDetails}
                              onSubmit={onSubmit}
                              canSubmit={
                                !isFetching && !!priceData && !isSubmitting
                              }
                            />
                          )}
                        </>
                      </Stack>
                    </Column>
                  </Columns>
                  <Box maxWidth="800px">
                    <FinancialStrengthTitleContainer>
                      <InfoBox title="" withIcon={false}>
                        <FinancialStrengthTitle>
                          <Heading
                            component="h3"
                            size={4}
                            color={config.brand.customisation?.headersColor}
                          >
                            {content.financialStrengthTitle}
                          </Heading>
                        </FinancialStrengthTitle>
                        <FinancialStrengthRating>
                          <Heading
                            component="span"
                            size={2}
                            color={config.brand.customisation?.headersColor}
                          >
                            {content.financialStrengthRating}
                          </Heading>
                        </FinancialStrengthRating>
                        <MarkdownContent
                          content={content.financialStrengthContent}
                        />
                      </InfoBox>
                    </FinancialStrengthTitleContainer>
                  </Box>
                </Stack>
              </Container>
              <NeedAdviceButton className="fixedFloating" />
            </CustomisedForm>
          );
        }}
      </Formik>
    </Box>
  );
};

export default BuyNowPage;
