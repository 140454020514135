import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import config from 'src/utils/env';
import { prepareHeaders } from '../serviceHelpers';
import { onFetchPriceQueryStarted } from './eventHandlers';
import {
  PriceApiFetchPriceRequest,
  PriceApiFetchPriceResponse,
} from './priceApiTypes';

const priceApi = createApi({
  reducerPath: 'priceApi',
  tagTypes: ['prices'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.pricingApiRoot}`,
    prepareHeaders,
  }),
  endpoints(builder) {
    return {
      fetchPrice: builder.query<
        PriceApiFetchPriceResponse,
        PriceApiFetchPriceRequest
      >({
        query: (request) => {
          return {
            url: '/api/health/nz/price',
            method: 'POST',
            body: request,
            headers: {
              // Note that we generate the correlation ID on the fly here.
              // This means that the full correlation ID sent to the pricing service
              // won't match the full correlation ID sent to the logging service
              // in they onQueryStarted handler.  For requests like pricing that
              // get generated through RTK Query when a component gets rendered, generating
              // a correlation ID to include in the request as part of what gets passed
              // to the query causes the query to run over and over since the input
              // changes each time the component gets rendered.  So, instead we generate
              // the correlation id here ... and accept that fact that the full value won't
              // match that used in the logging messages.
              // However, the correlation id consists of 2 uuids separated by a colon.
              // The first uuid WILL be common across the pricing and logging requests
              // so can be used to query sumo logic and see the 3 related messages.
              //
              'x-correlation-id': generateCorrelationId(),
            },
          };
        },
        providesTags: ['prices'],
        onQueryStarted: onFetchPriceQueryStarted,
      }),
    };
  },
});

export const { useFetchPriceQuery, useLazyFetchPriceQuery } = priceApi;

export { priceApi };
